<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="SURAT SETORAN RETRIBUSI DAERAH" class="panel panel-success">
            <!-- Judul -->
            <div>
                <b-row>
                    <b-col md="8">
                        <h4>SSRD</h4>
                        <p>
                            <b>Surat Setoran Retribusi Daerah, </b> yang
                            selanjutnya disingkat <b>SSRD</b>, adalah bukti
                            pembayaran atau penyetoran retribusi yang telah
                            dilakukan dengan menggunakan formulir atau telah
                            dilakukan dengan cara lain ke kas daerah melalui
                            tempat pembayaran yang ditunjuk oleh Kepala Daerah.
                        </p>
                    </b-col>
                    <b-col md="4">
                        <div class="float-right">
                            <span class="px-2">
                                <i class="fa fa-info-circle fa-2x"></i>
                            </span>
                            <span class="h4">PEMBAYARAN SSRD</span>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <hr />
            <!-- end of judul -->

            <!-- form data -->
            <form
                @submit.prevent="storeUpdateBuktiBayar"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. STS <span class="text-danger">*</span></label
                    >
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-sm-4">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('no_sts'),
                                    }"
                                    placeholder="Masukkan Nomor STS"
                                    v-model="form.no_sts"
                                />
                                <em
                                    v-if="form.errors.has('no_sts')"
                                    class="form-text text-danger"
                                    ><i class="fa fa-exclamation-triangle"></i>
                                    {{ errors.no_sts[0] }}</em
                                >
                            </div>
                            <div
                                class="col-sm-3"
                                @click="cekKodeBayar(form.no_sts)"
                            >
                                <b-button variant="success">
                                    <span v-if="cekLoading">
                                        <b-spinner
                                            variant="primary"
                                            label="Spinning"
                                            small
                                        ></b-spinner>
                                    </span>
                                    Cek Kode Bayar
                                </b-button>
                            </div>
                        </div>
                        <div v-if="cekLoading" class="text-center mt-5">
                            <b-spinner
                                variant="primary"
                                label="Spinning"
                                small
                            ></b-spinner>
                            <div>
                                Memuat...
                            </div>
                        </div>
                        <div v-else>
                            <b-table-simple
                                small
                                bordered
                                class="mt-3"
                                v-if="detailKodeBayar"
                            >
                                <b-th colspan="5" class="text-center">
                                    <h5>NOTA PERHITUNGAN RETRIBUSI DAERAH</h5>
                                </b-th>
                                <b-tr>
                                    <b-td
                                        style="width: 150px"
                                        class="font-weight-bold"
                                    >
                                        Nama Wajib Retribusi
                                    </b-td>
                                    <b-td style="width: 450px">
                                        {{ detailKodeBayar.spt.npwrd.nama }}
                                    </b-td>
                                    <b-td class="font-weight-bold">
                                        No./Tgl. SPTRD
                                    </b-td>
                                    <b-td>
                                        {{ detailKodeBayar.spt.no_spt }}
                                    </b-td>
                                    <b-td>
                                        {{ detailKodeBayar.spt.tgl_spt }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td
                                        style="width: 150px"
                                        class="font-weight-bold"
                                    >
                                        NPWRD
                                    </b-td>
                                    <b-td>
                                        {{ detailKodeBayar.spt.npwrd.no_pokok }}
                                    </b-td>
                                    <b-td class="font-weight-bold">
                                        No./Tgl. SKRD
                                    </b-td>
                                    <b-td>
                                        {{ detailKodeBayar.surat.no_surat }}
                                    </b-td>
                                    <b-td>
                                        {{ detailKodeBayar.surat.tgl_surat }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td class="font-weight-bold">
                                        Biaya Retribusi
                                    </b-td>
                                    <b-td colspan="4">
                                        <h4>
                                            Rp.
                                            {{
                                                detailKodeBayar.nilai_terutang
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        "."
                                                    )
                                            }}
                                        </h4>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                            <!-- Table -->
                            <!-- Data Table & Pagination -->
                            <b-table
                                show-empty
                                small
                                id="masTable"
                                ref="masTable"
                                class="mt-3"
                                head-variant="light"
                                :hover="true"
                                :busy.sync="isBusy"
                                :items="ssrdList"
                                :fields="fields"
                                responsive
                                bordered
                                v-if="detailKodeBayar"
                            >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:table-busy>
                                    <div class="text-center text-success my-2">
                                        <b-spinner
                                            class="align-middle"
                                            small
                                        ></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                                <template v-slot:table-colgroup="scope">
                                    <col
                                        v-for="field in scope.fields"
                                        :key="field.key"
                                        :style="{
                                            width:
                                                field.key === 'actions'
                                                    ? '105px'
                                                    : '',
                                        }"
                                    />
                                </template>

                                <template #head()="data">
                                    <span style="white-space: pre;">{{
                                        data.label
                                    }}</span>
                                </template>

                                <template #cell(ret_rincian)="data">
                                    <div v-html="data.item.ret_rincian"></div>
                                </template>

                                <template #cell(tarif)="data">
                                    {{ data.item.tarif.toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                "."
                                            )}}
                                </template>

                                <template #cell(qty)="data">
                                    {{
                                        data.item.qty
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                "."
                                            )
                                    }}
                                </template>

                                <template #cell(nilai_terutang)="data">
                                    {{
                                        data.item.nilai_terutang
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                "."
                                            )
                                    }}
                                </template>
                                <template #custom-foot>
                                    <b-tr>
                                        <b-td
                                            colspan="3"
                                            class="bg-black-transparent-3"
                                        >
                                            <span
                                                class="float-right font-weight-bold"
                                            >
                                                Jumlah
                                            </span>
                                        </b-td>
                                        <b-td
                                            class="font-weight-bold text-right bg-black-transparent-3"
                                        >
                                            {{
                                                sumTable.jumlah
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        "."
                                                    )
                                            }}
                                        </b-td>
                                        <b-td
                                            class="font-weight-bold text-right bg-black-transparent-3"
                                        >
                                            {{
                                                sumTable.total_biaya
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        "."
                                                    )
                                            }}
                                        </b-td>
                                    </b-tr>
                                </template>
                            </b-table>
                            <!-- end of table -->
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Metode Pembayaran</label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <v-select
                                    v-model="form.metode_bayar"
                                    :options="['Bendahara Penerimaan', 'QRIS Statis']"
                                    placeholder="Pilih Metode Pembayaran"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nama Penyetor <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'penyetor'
                                        ),
                                    }"
                                    placeholder="Masukkan Nama Penyetor"
                                    v-model="form.penyetor"
                                />
                                <em
                                    v-if="form.errors.has('penyetor')"
                                    class="form-text text-danger"
                                    ><i class="fa fa-exclamation-triangle"></i>
                                    {{ errors.penyetor[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nama Penerima (Optional)</label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Masukkan Nama Penerima"
                                    v-model="form.penerima"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="pt-2">
                    <b-row>
                        <b-col>
                            <div>
                                <span>
                                    <b-button
                                        variant="primary"
                                        type="submit"
                                        :disabled="simpanDisable"
                                    >
                                        <span v-if="isLoading">
                                            <b-spinner
                                                variant="primary"
                                                label="Spinning"
                                                small
                                            ></b-spinner>
                                        </span>
                                        <i class="fa fa-save" v-else></i> Simpan
                                    </b-button>
                                </span>
                                <span class="mx-2">
                                    <b-button variant="secondary" @click="clear">
                                        <i class="fa fa-redo-alt"></i> Clear
                                    </b-button>
                                </span>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="float-right">
                                <b-button
                                    variant="success"
                                    @click="showPdf(no_sts)"
                                    v-if="lihatVar"
                                    ><i class="fa fa-file-pdf"></i> Lihat
                                    SSRD</b-button
                                >
                                <b-button
                                    variant="success"
                                    @click="getPdfTiket(ssrdList[0].spt_id)"
                                    v-if="lihatVar"
                                    ><i class="fa fa-file-pdf"></i> Lihat
                                    Tiket Masuk</b-button
                                >
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </form>
            <!-- end of form-->
        </panel>
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "../../../components/npwr/HRetribusi.vue";
import ssrdMixin from "@/helper/api/ssrdDetail.js";
import axios from "axios";
import hapusStorage from '@/helper/hapusLocalStore'

export default {
    mixins: [ssrdMixin, hapusStorage],
    components: { HRetribusi },
    data() {
        return {
            ssrdList: [],
            form: new Form({
                no_sts: "",
                penyetor: "",
                penerima: "",
                metode_bayar: ""
            }),
            errors: [],
            isLoading: false,
            cekLoading: false,
            detailKodeBayar: null,
            lihatVar: false,
            showLoader: false,
            pdfUrl: "",
            no_sts: "",
            // fields
            fields: [
                {
                    key: "index",
                    label: "No.",
                    tdClass: "text-right"
                },
                {
                    key: "ret_rincian",
                    label: "Rincian Retribusi",
                    sortable: true,
                },
                {
                    key: "tarif",
                    label: "Tarif (Rp.)",
                    sortable: true,
                    tdClass: "text-right",
                },
                {
                    key: "qty",
                    label: "Jumlah",
                    sortable: true,
                    tdClass: "text-right",
                },
                {
                    key: "nilai_terutang",
                    label: "BIAYA RETRIBUSI (Rp.)",
                    sortable: true,
                    tdClass: "text-right",
                },
            ],
            sumTable: {
                jumlah: "",
                total_biaya: "",
            },
            simpanDisable: false,
        };
    },
    watch: {
        $route(to, from) {
            if (this.$route.name === "BuatSSRD") {
                this.form.reset();
                this.form.clear();
            }
        },
    },
    mounted() {
        if (this.$route.name === "EditSSRD") {
            this.detailSSRD(this.$route.params.id);
            this.ssrdDisable = false;
        }
    },
    methods: {
        storeUpdateBuktiBayar() {
            this.isLoading = true;
            this.form
                .put("/api/transaksi/ssr/bukti-bayar/cash")
                .then((response) => {
                    this.isLoading = false;
                    this.lihatVar = true;
                    this.simpanDisable = true;
                    this.no_sts = response.data.data.no_sts;
                    this.$swal({
                        title: "Data Berhasil Dibuat",
                        text:
                            "Untuk melihat SSRD yang sudah dibuat, silahkan lihat pada halaman List SSRD.",
                        icon: "success",
                        showCloseButton: true,
                        timer: 5000,
                    });
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.lihatVar = false;
                    this.simpanDisable = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 403) {
                        this.$swal({
                            title: "Terjadi Masalah",
                            text: error.response.data.status.message,
                            icon: "error",
                            showCloseButton: true,
                            timer: 5000,
                        });
                    } else if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        cekKodeBayar(value) {
            this.cekLoading = true;
            axios
                .get("/api/transaksi/kode-bayar/kode-" + value)
                .then((response) => {
                    this.cekLoading = false;
                    this.detailKodeBayar = response.data.data;
                    this.lihatSSRD(response.data.data.spt_id);
                })
                .catch((error) => {
                    this.cekLoading = false;
                    if (error.response.status === 404) {
                        this.$swal({
                            title: "Data Nomor STSRD Tidak Tersedia",
                            icon: "error",
                            showCloseButton: true,
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            confirmButtonClass: "btn btn-primary",
                            timer: 5000,
                        });
                        this.detailKodeBayar = null;
                    } else if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        showPdf(id) {
            this.getPdf(id);
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },
        // view pdf
        getPdf(id) {
            this.showLoader = true
            axios
                .get("/api/view-pdf/surat/ssr/by-sts/" + id, {
                    responseType: "arraybuffer",
                    timeout: 5000,
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    this.showLoader = false
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        getPdfTiket(id) {
            this.showLoader = true
            axios
                .get("/api/view-pdf/surat/ssr/tiket-masuk/spt-" + id, {
                    responseType: "arraybuffer",
                    timeout: 10000,
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    this.showLoader = false
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        lihatSSRD(value) {
            this.isBusy = true;
            axios
                .get("/api/transaksi/spt-detail", {
                    params: {
                        spt_id: value,
                    },
                })
                .then((response) => {
                    this.isBusy = false;
                    const items = response.data.data;
                    this.sumTable.jumlah = response.data.meta.sum.qty;
                    this.sumTable.total_biaya =
                        response.data.meta.sum.nilai_terutang;
                    this.ssrdList = items;
                    this.biaya_retribusi = response.data.meta.sum.nilai;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        clear () {
            this.form.reset()
            this.form.clear()
            this.detailKodeBayar = null
            this.simpanDisable = false
            this.lihatVar = false
        }
    },
};
</script>
