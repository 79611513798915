import axios from 'axios'
import hapusStorage from '../hapusLocalStore'

export const ssrdMixin = {
    mixins:[hapusStorage],
    methods: {
        detailSSRD (id) {
            axios.get("/api/transaksi/ssr/" + id).then(response => {
                const items = response.data.data
                this.detailKodeBayar = items
                this.no_sts = items.no_sts
                this.form.fill(items)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        }
    }
}

export default ssrdMixin